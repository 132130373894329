import React, { useEffect, useState } from 'react';

export const ConditionalBlock = ({ data }) => {
  const [companyIndustry, setCompanyIndustry] = useState(null);

  // Function to listen for changes in the dataLayer
  const handleDataLayerChange = () => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      const dataLayerItem = window.dataLayer.find(
        (item) => item.company_industry
      );
      if (dataLayerItem && dataLayerItem.company_industry) {
        setCompanyIndustry(dataLayerItem.company_industry);
      }
    }
  };

  useEffect(() => {
    // Listen for dataLayer.push events
    const dataLayerPush = (event) => {
      if (event.detail && event.detail[0].company_industry) {
        setCompanyIndustry(event.detail[0].company_industry);
      }
    };

    // Initial check if dataLayer already has the company_industry value
    handleDataLayerChange();

    // Listen for new pushes to the dataLayer
    window.addEventListener('dataLayerPush', dataLayerPush);

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener('dataLayerPush', dataLayerPush);
    };
  }, []);

  // Define content for each industry
  const financialServices = data.field_financial_services?.[0]?.value;
  const manufacturing = data.field_manufacturing?.[0]?.value;

  let returnValue = '';
  let personalizationData = {};

  if (companyIndustry === 'Manufacturing') {
    returnValue = manufacturing;
    personalizationData = {
      p_type: 'full_width_block',
      p_focus: companyIndustry,
      p_content: 'Manufacturing industry insights',
      p_button_text: 'Learn More',
      p_button_link: 'http://manufacturing-link.com',
    };
  } else if (companyIndustry === 'Financial_Services') {
    returnValue = financialServices;
    personalizationData = {
      p_type: 'full_width_block',
      p_focus: companyIndustry,
      p_content: 'Financial Services report',
      p_button_text: 'Download the report',
      p_button_link: 'http://financial-services-link.com',
    };
  } else if (!companyIndustry) {
    returnValue = '';
  }

  // Function to handle user interaction and push data to dataLayer
  const handleInteraction = () => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      // Create a complete object to push to the dataLayer
      const completeData = {
        event: 'personalization_block',
        ...personalizationData,
        page_referrer: document.referrer,
        page_location: window.location.href,
        page_title: document.title,
        company_id: '123456', // Replace with actual company_id if available
        company_name: 'Demo Company', // Replace with actual company_name if available
        company_industry: companyIndustry,
        gtm_segmentation: 'segment_value', // Replace with actual segmentation if needed
        sfdcParentId: '0123456789', // Replace with actual ID if available
      };

      // Push the complete object to the dataLayer
      window.dataLayer.push(completeData);

      // Log the complete object after all data is merged
      console.log('Data pushed to dataLayer:', completeData);
    }
  };

  return (
    <div className="conditional-block">
      <h1>{returnValue}</h1>
      {returnValue && (
        <button onClick={handleInteraction}>
          {personalizationData.p_button_text}
        </button>
      )}
    </div>
  );
};
